// Constantes
import { ADDONS } from '@/constants'
import { AVERAGE_SHIPPING_TIMES, ORDER_TYPES, PAYMENT_TYPES, TIME_SLOTS } from '../../constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Utils
import { get, cloneDeep } from 'lodash'
import { isDecimalNumber } from '@/utils'

export default {
  name: 'TakeAwayConfigBase',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons,
    VuetifyToolBar
  },
  mixins: [formMixin, uiMixin, validationMixin, addonsMixin],
  data() {
    return {
      // Form
      formFields: {
        averageShippingTime: AVERAGE_SHIPPING_TIMES[0].value,
        enabledFreeShippingCost: false,
        description: null,
        paymentTypes: [],
        orderMinimumPriceWithoutShippingCost: 0,
        orderMinimumPrice: 0,
        serviceFee: 0,
        serviceTypes: 'both',
        shippingCost: 0,
        soundNotifications: false,
        // Preorder
        preorderEnabled: false,
        preorderTimeSlotLength: 15,
        preorderMaxOrdersPerSlot: 5,
        preorderFutureMaxDays: 7,
        preoderMinDays: 1
      },
      formFieldsValidations: {
        averageShippingTime: {
          required: 'Campo obligatorio'
        },
        paymentTypes: {
          required: 'Debe seleccionar al menos una opción'
        },
        orderMinimumPriceWithoutShippingCost: {
          required: 'Campo obligatorio',
          numberFormat: 'Debe colocar un valor válido'
        },
        orderMinimumPrice: {
          required: 'Campo obligatorio',
          numberFormat: 'Debe colocar un valor válido'
        },
        shippingCost: {
          required: 'Campo obligatorio',
          numberFormat: 'Debe colocar un valor válido'
        }
      },
      // Others
      averageShippingTimes: AVERAGE_SHIPPING_TIMES,
      everyPaymentTypes: [...Object.values(PAYMENT_TYPES)],
      everyServiceTypes: [{ label: 'Ambos', value: 'both' }, ...Object.values(ORDER_TYPES)],
      // Preorder
      timeSlots: TIME_SLOTS
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * ¿Solo ofrece servicio de recogida?
     *
     * @return {Boolean}
     */
    isOnlyTakeAwayService() {
      return this.formFields.serviceTypes === ORDER_TYPES.takeAway.value
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['updateConfigs']),
    /**
     * Establece los valores inciales del formulario
     */
    getEveryNeededData() {
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const takeAwayConfig = get(this.placeAddonsSetupByUser, ADDONS.takeAway, {})

      this.formFields = {
        averageShippingTime: get(
          takeAwayConfig,
          'averageShippingTime',
          this.formFields.averageShippingTime
        ),
        enabledFreeShippingCost: get(
          takeAwayConfig,
          'enabledFreeShippingCost',
          this.formFields.enabledFreeShippingCost
        ),
        description: get(takeAwayConfig, 'description', this.formFields.description),
        paymentTypes: cloneDeep(get(takeAwayConfig, 'paymentTypes', this.formFields.paymentTypes)),
        orderMinimumPriceWithoutShippingCost: get(
          takeAwayConfig,
          'orderMinimumPriceWithoutShippingCost',
          this.formFields.orderMinimumPriceWithoutShippingCost
        ),
        orderMinimumPrice: get(
          takeAwayConfig,
          'orderMinimumPrice',
          this.formFields.orderMinimumPrice
        ),
        serviceFee: get(takeAwayConfig, 'serviceFee', this.formFields.serviceFee),
        serviceTypes: get(takeAwayConfig, 'serviceTypes', this.formFields.serviceTypes),
        shippingCost: get(takeAwayConfig, 'shippingCost', this.formFields.shippingCost),
        soundNotifications: get(
          takeAwayConfig,
          'soundNotifications',
          this.formFields.soundNotifications
        ),
        // Preorder
        preorderEnabled: get(takeAwayConfig, 'preorderEnabled', this.formFields.preorderEnabled),
        preorderTimeSlotLength: get(
          takeAwayConfig,
          'preorderTimeSlotLength',
          this.formFields.preorderTimeSlotLength
        ),
        preorderMaxOrdersPerSlot: get(
          takeAwayConfig,
          'preorderMaxOrdersPerSlot',
          this.formFields.preorderMaxOrdersPerSlot
        ),
        preorderFutureMaxDays: get(
          takeAwayConfig,
          'preorderFutureMaxDays',
          this.formFields.preorderFutureMaxDays
        ),
        preoderMinDays: get(takeAwayConfig, 'preoderMinDays', this.formFields.preoderMinDays)
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Actualizamos configuraciones
      await this.updateConfigs({
        id: this.placeData.id,
        addOns: [
          {
            id: ADDONS.takeAway,
            configFields: this.formFields
          }
        ]
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  validations: {
    formFields: {
      averageShippingTime: {
        required
      },
      description: {},
      enabledFreeShippingCost: {},
      paymentTypes: {
        required,
        minLength: minLength(1)
      },
      orderMinimumPriceWithoutShippingCost: {
        required,
        numberFormat: (value) => {
          return value ? isDecimalNumber(value) : true
        }
      },
      orderMinimumPrice: {
        required,
        numberFormat: (value) => {
          return value ? isDecimalNumber(value) : true
        }
      },
      serviceFee: {},
      serviceTypes: {},
      shippingCost: {
        required,
        numberFormat: (value) => {
          return value ? isDecimalNumber(value) : true
        }
      },
      soundNotifications: {},
      // Preorder
      preorderEnabled: {},
      preorderTimeSlotLength: {},
      preorderMaxOrdersPerSlot: {},
      preorderFutureMaxDays: {},
      preoderMinDays: {}
    }
  }
}
